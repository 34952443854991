<template>
  <v-app>
    <!-- 1°first ROW -->
    <div class="row">
      <div class="col-md-12">
        <!-- 1°first Card -->
        <v-card outlined tile elevation="1">
          <v-card-title>
            Stock Productos
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on}">
                <v-btn v-on="on" class @click="fetchData" text icon color="blue">
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>Actualizar Tabla</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" class @click="exportExcel" text icon color="green">
                  <v-icon>fas fa-file-excel</v-icon>
                </v-btn>
              </template>
              <span>Exportar Excel</span>
            </v-tooltip>
          </v-card-title>
          <v-card-text>
            <v-card outlined tile elevation="1">
              <v-card-text style="padding-bottom: 0px;">
                <div class="d-flex justify-space-between mb-4">
                  <v-text-field
                    v-model="search"
                    clearable
                    label="Buscar"
                    hide-details
                    dense
                    solo
                    class="mr-2"
                  ></v-text-field>
                  <v-btn class="btn-starkoms-primary" dark style="min-width: 138px;" @click="fetchData" >Buscar</v-btn>
                </div>
              </v-card-text>
            </v-card>
          </v-card-text>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="listado"
              :items-per-page="selectItemsPerPage"
              :disable-pagination="true"
              :hide-default-footer="true"
              :loading="dataTableLoading"
              :sort-by="'id'"
              :sort-desc="true"
              :disable-sort="true"
              no-results-text="No hay resultados disponibles"
              no-data-text="No hay datos disponibles"
              loading-text="Cargando datos..."
              class="elevation-1 datatable"
            >
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <router-link :to="`/inventario/stock/productos/${item.sku}`">
                      <v-icon v-on="on" color="#93a2dd" small class="mr-2">mdi-eye</v-icon>
                    </router-link>
                  </template>
                  <span>Ver Producto SKU: {{item.sku}}</span>
                </v-tooltip>
              </template>
            </v-data-table>
            <div class="d-flex justify-space-between mt-4">
              <v-select
                style="max-width: 120px"
                @input="fetchData"
                v-model="selectItemsPerPage"
                label="Registros por página"
                :items="[10,20,50,100, 500]"
              ></v-select>
              <v-pagination
                @input="fetchData"
                v-model="page"
                :length="totalPages"
                :total-visible="100"
                color="#e55d43"
              ></v-pagination>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";

export default {
  data: () => ({
    page: 1,
    totalPages: 1,
    pageCount: 0,
    selectItemsPerPage: 10,
    readonly: true,
    boolean: false,
    dataTableLoading: false,
    search: "",
    headers: [
      { text: "ID", align: "start", sortable: false, value: "id", },
      { text: "Producto", value: "name" },
      { text: "Sku", value: "sku" },
      { text: "Stock disponible", value: "summary.quantity" },
      { text: "Stock físico", value: "summary.physical_quantity" },
      { text: "Stock reservado", value: "summary.reserved_quantity" },
      { text: "Acciones", value: "actions", sortable: false },
    ],
    listado: [],
  }),
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Lista de Productos", route: "/inventario/stock/productos" },
    ]);
    this.loading = true;
    this.fetchData();
  },
  created() {},

  methods: {
    initialize() {},
    fetchData(params = null) {
        this.dataTableLoading = true;
        var vm = this;
        const sortBy = "id";
        const sortDesc = true;
        const page = this.page;
        const itemsPerPage = this.selectItemsPerPage;
        const search = this.search;
        
        this.axios({
        url: "inventory/products/paginated",
        method: "GET",
        params: {
          page,
          perPage: itemsPerPage,
          sortBy: sortBy,
          sortDesc: sortDesc ? 1 : 0,
          search,
        },
      })
        .then((response) => {
          let arreglo = [];
          if (response.data.data instanceof Object) {
            Object.keys(response.data.data).forEach((key) => {
              arreglo.push(response.data.data[key]);
            });
            vm.listado = arreglo;
          } else {
            vm.listado = response.data.data.map((e) => e);
          }
          vm.totalPages = response.data.last_page;
          vm.dataTableLoading = false;
          vm.selected = [];
        })
        .catch((error) => {
          console.log(error);
          vm.dataTableLoading = false;
        });
    },
    exportExcel(){
      var vm = this;
      this.axios({
        url: 'inventory/stock/export/excel',
        method: 'POST',
        responseType: "blob",
      }).then( response => {
        const newBlob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
        });
        const data = window.URL.createObjectURL(newBlob);
        window.open(data, "_blank");
      }).catch( error => {
        console.log(error);
      });
    },
    
  },
};
</script>